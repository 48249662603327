import React from "react";
import { planPackageType } from "../../context/types";
import { useSubscription } from "../../context/subscriptionContextProvider";

export const SubscriptionGuard = ({
  children,
  planPackageType
}: {
  children: React.ReactNode;
  planPackageType: planPackageType | planPackageType[];
}) => {
  const {checkCurrentPackageType} = useSubscription();
  const hasPermission = checkCurrentPackageType(planPackageType);

  if (hasPermission) {
    return <>{children}</>;
  }

  return <></>;
};
